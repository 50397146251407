import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { v4 } from 'uuid'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useTheme } from '@chakra-ui/react'

import {
  Article,
  Section,
  Hero,
  RowContent,
  ColImage,
  ColInfo,
  P,
  Gallery,
  Image,
  ColVideo,
  H3,
  HeroBody,
  HeroSubtitle,
  HeroTitle,
} from '../ui'

import Layout from '../components/layout'

function IndexPage({ data }) {
  const { t } = useTranslation()
  const theme = useTheme()

  const [bookSession, setBookSession] = useState('')

  return (
    <Layout title={t('index:title')}>
      <Article>
        <Hero
          minHeight={{ base: '400px', md: '500px', lg: '600px' }}
          image={<Image fluid={data.hero.childImageSharp.fluid} layout="background" />}
        >
          <HeroBody textAlign="center" maxWidth="860px" spacing={2} marginTop={{ base: 10, md: 4 }}>
            <HeroTitle fontWeight="black" fontSize={{ base: '4xl', md: '5xl', lg: '6xl' }}>
              Experiencias Para Toda La Familia
            </HeroTitle>
            <HeroSubtitle fontSize={{ base: '2xl' }}>
              Seleccionamos las mejores promociones para que tengas una increíble experiencia
              familiar al mejor precio.
            </HeroSubtitle>
          </HeroBody>
        </Hero>

        <Section></Section>
      </Article>
    </Layout>
  )
}

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "index/hero1.jpg" }) {
      ...bannerSectionImage
    }
  }
`

export default IndexPage
